<template>
  <div class="d-flex flex-column flex-shrink-0 vh-100 overflow-hidden">
    <section-login />
  </div>
</template>

<script>
import SectionLogin from '@/components/partials/login/Login.vue'

export default {
  components: {
    SectionLogin
  }
};
</script>